<template>
  <div>
    <div v-if="joinSuccessful" class="flex flex-col gap-6">
      <h1 class="text-2xl font-bold">Welcome to favoritely.com</h1>
      <p class="text-gray-600 -mt-4">
        Your account has been created successfully!
      </p>
      <UAlert
        description="Please check your email for a verification link to finish the signup process."
        color="primary"
        title="Your Next Step"
        :ui="{
          title: 'text-lg font-bold',
          description: 'text-lg',
          gap: 'items-center',
        }"
        :actions="[
          {
            variant: 'solid',
            color: 'white',
            label: 'Resend Confirmation Email',
            click: resendEmailConfirmation,
          },
        ]"
      >
        <template #icon>
          <font-awesome-icon
            icon="fa-light fa-circle-exclamation"
            class="text-3xl text-white cursor-pointer"
            style="aspect-ratio: 1/1"
          />
        </template>
      </UAlert>
      <p class="text-sm mt-4 text-cool-400 dark:text-cool-600">
        Emails can be delivered to more than inbox and spam folders. Be sure to
        use your email's search functionality to search for "favoritely.com". If
        you are having trouble logging in to your account, contact
        <a href="mailto:support@favoritely.com">support@favoritely.com</a>
      </p>
    </div>
  
    <div v-else class="flex flex-col gap-3">
      <slot name="header">
        <div class="flex flex-row items-center gap-3">
          <Logo :loading="formPending" variant="square" size="h-6" />
          <h1 class="text-2xl font-bold">Sign Up</h1>
        </div>
  
        <div class="flex flex-col gap-2">
          <p>
            It's
            <strong class="text-[#EA1662]">FREE</strong>
            to join!
          </p>
          <ul class="list-disc list-inside text-sm">
            <li>
              <strong class="text-[#EA1662]">Tip</strong>
              creators to show your
              <strong class="text-[#EA1662]">support</strong>
              and appreciation.
            </li>
            <li>
              <strong class="text-[#EA1662]">Subscribe</strong>
              to unlock
              <strong class="text-[#EA1662]">exclusive access</strong>
              to their content.
            </li>
            <li>
              Send
              <strong class="text-[#EA1662]">private messages</strong>
              to connect directly with your favorite creators.
            </li>
          </ul>
        </div>
      </slot>
  
      <p class="flex flex-row justify-center items-center gap-3 pt-2">
        <span>Already have an account?&nbsp;</span>
        <Button to="/login" theme="primary" class="px-5">Sign in</Button>
      </p>
  
      <p class="text-red-600">
        {{ form.error }}
      </p>
  
      <form @submit.prevent="performJoin" class="w-full flex flex-col gap-4">
        <FormGroup label="Email" name="email" size="sm" class="relative">
          <FormInput
            v-model="form.data.email"
            label="Email"
            placeholder="mail@provider.com"
            type="email"
            :required="true"
          />
        </FormGroup>
  
        <FormGroup label="Username" name="username" size="lg" class="relative">
          <FormUsernameInput
            v-model="form.data.username"
            @valid="handleValidUsername"
            @invalid="handleInvalidUsername"
            @clear="handleClearUsername"
          />
        </FormGroup>
  
        <FormGroup label="Password" name="password" size="sm" class="relative">
          <FormPasswordInput
            v-model="form.data.password"
            placeholder="Your password"
          />
        </FormGroup>
  
        <div class="flex flex-col gap-1 text-xs mt-2">
          <Checkbox v-model="form.data.terms" id="terms" type="checkbox">
            I agree to the
            <NuxtLink
              to="/general-terms-of-service"
              target="_blank"
              tabindex="-1"
            >
              Terms of Use
            </NuxtLink>
            and
            <NuxtLink to="/privacy" target="_blank" tabindex="-1">
              Privacy Policy
            </NuxtLink>
            .
          </Checkbox>
  
          <Checkbox
            v-model="form.data.age"
            type="checkbox"
            label="I certify, that I am at least 18 years old and of lawful age."
            id="age"
          />
        </div>
      </form>
  
      <Button
        theme="primary"
        :loading="formPending"
        :disabled="formPending"
        :block="true"
        @click="performJoin"
      >
        Sign Up
      </Button>
  
      <div class="flex flex-col gap-2">
        <Button theme="secondary" size="xl" block @click="loginWithGoogle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M4.04607 10.1023L3.48911 12.1815L1.45344 12.2246C0.845075 11.0962 0.5 9.80515 0.5 8.43323C0.5 7.10658 0.822637 5.85554 1.39453 4.75397H1.39497L3.20729 5.08623L4.00119 6.88767C3.83503 7.37209 3.74446 7.89211 3.74446 8.43323C3.74452 9.0205 3.8509 9.58317 4.04607 10.1023Z"
              fill="#FBBB00"
            />
            <path
              d="M16.3604 6.93854C16.4523 7.42249 16.5002 7.92229 16.5002 8.43309C16.5002 9.00586 16.44 9.56457 16.3252 10.1035C15.9358 11.9374 14.9182 13.5388 13.5085 14.672L13.508 14.6716L11.2253 14.5551L10.9022 12.5383C11.8376 11.9897 12.5687 11.1312 12.9537 10.1035H8.67578V6.93854H13.0162H16.3604Z"
              fill="#518EF8"
            />
            <path
              d="M13.509 14.6713L13.5095 14.6718C12.1385 15.7738 10.3968 16.4332 8.50095 16.4332C5.45425 16.4332 2.80537 14.7303 1.4541 12.2242L4.04673 10.102C4.72235 11.9051 6.46175 13.1887 8.50095 13.1887C9.37745 13.1887 10.1986 12.9517 10.9032 12.5381L13.509 14.6713Z"
              fill="#28B446"
            />
            <path
              d="M13.6064 2.2745L11.0146 4.39632C10.2854 3.94049 9.42334 3.67717 8.4998 3.67717C6.41444 3.67717 4.6425 5.01963 4.00073 6.88741L1.39448 4.75371H1.39404C2.72553 2.18659 5.40781 0.432678 8.4998 0.432678C10.441 0.432678 12.2208 1.12414 13.6064 2.2745Z"
              fill="#F14336"
            />
          </svg>
          <span class="text-cool-600 dark:text-white">Sign up with Google</span>
        </Button>
        <Button theme="secondary" size="xl" block @click="loginWithX">
          <font-awesome-icon
            icon="fa-brands fa-x-twitter"
            class="text-black dark:text-white"
          />
          <span class="text-cool-600 dark:text-white">Sign up with X</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useUserStore } from "@/store/user"

  const userStore = useUserStore()
  const toast = useToast()

  const props = defineProps({
    redirectBackToUrl: {
      type: String,
      required: false,
    },
  })

  const form = reactive({
    data: {
      age: false,
      email: null,
      username: null,
      password: null,
      terms: false,
    },
    error: null,
  })
  const formPending = ref(false)
  const joinSuccessful = ref(false)
  const config = useRuntimeConfig()

  const emit = defineEmits(["join-successful"])

  const isCreatorSignup = computed(() => {
    const params = new URLSearchParams(props.redirectBackToUrl.split("?")[1])
    return params.get("creatorSignup") || false
  })

  const loginWithGoogle = async () => {
    let requestUrl = `${config.public.API_URL}/api/oauth/google/redirect_uri`
    if (props.redirectBackToUrl) {
      requestUrl = `${requestUrl}?redirectBackTo=${props.redirectBackToUrl}`
    }
    const uri = await $fetch(requestUrl)
    window.open(uri, "_self")
  }

  const loginWithX = async () => {
    let requestUrl = `${config.public.API_URL}/api/oauth/x/redirect_uri`
    if (props.redirectBackToUrl) {
      requestUrl = `${requestUrl}?redirectBackTo=${props.redirectBackToUrl}`
    }
    const uri = await $fetch(requestUrl)
    window.open(uri, "_self")
  }

  const performJoin = async () => {
    try {
      form.error = null
      formPending.value = true

      const config = useRuntimeConfig()
      const referral_cookie = useCookie("ref")
      const result = await $api(`${config.public.API_URL}/api/sign_ups`, {
        method: "POST",
        body: {
          user: {
            age: form.data.age,
            email: form.data.email,
            username: form.data.username,
            password: form.data.password,
            terms_of_service: form.data.terms,
            referral_id: referral_cookie.value,
          },
        },

        headers: {
          "Content-Type": "application/json",
        },
      })
      userStore.setLoginDetails(result)
      joinSuccessful.value = true
      emit("join-successful", result)
    } catch (error) {
      const errors = useErrorMessage(error.response._data)
      form.error = errors
    } finally {
      formPending.value = false
    }
  }

  const handleValidUsername = (username) => {
    form.data.username = username
    form.error = null
  }

  const handleInvalidUsername = (message) => {
    form.error = message
  }

  const handleClearUsername = () => {
    form.data.username = null
    form.error = null
  }

  const resendEmailConfirmation = async () => {
    await $api(
      `${config.public.API_URL}/api/me/settings/resend_email_confirmation`,
      {
        method: "POST",
        body: {},
      },
    )

    toast.add({
      title: "Email sent",
      description: "Please check all email folders",
    })
  }
</script>
